<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <div class="bannerbox">
        <img src="../../assets/imgs/webimg/aboutbannerbg.png" alt="" />
      </div>
      <div class="contentwrap">
        <el-menu
          router
          default-active="1"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item index="1">支付页面</el-menu-item>
        </el-menu>
        <div class="centerbox">
          <div class="tittleh2" :id="dataInfo.productid">
            {{ dataInfo.product }}
          </div>
          <div class="titp lineb">
            应付金额：<span class="red">￥{{ dataInfo.money }}</span>
          </div>
          <div class="titp">支付方式：请选择合适的支付方式</div>
          <div class="imgwrap lineb">
            <div class="imgbox active">
              <img src="../../assets/imgs/webimg/zfb.png" alt="" />
            </div>
            <!-- <div class="imgbox">
              <img src="../../assets/imgs/webimg/wx.png" alt="" />
            </div> -->
          </div>
          <el-button type="primary" class="paybtn" @click="handelPay"
            >支 付</el-button
          >
        </div>
      </div>
      <footer-all></footer-all>
    </el-main>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
import {
  getPayRequest,
  payAlipayRequest,
  setOrderRequest,
} from "@/api/project.js";

export default {
  components: {
    NavMain,
    FooterAll,
  },
  data() {
    return {
      dataInfo: {},
      payUrl: "",
      orderno:''
    };
  },
  created() {
    this.getPayRequest();
  },
  methods: {
    async getPayRequest() {
      try {
        const result = await getPayRequest({
          ptype: this.type,
          productid: this.Id,
        });
        if (result.status == 200) {
          this.dataInfo = result.data;
        }
      } catch (err) {}
    },
    async handelPay() {
      try {
        const result = await setOrderRequest({
          ptype:this.dataInfo.ptype,
          productid: this.dataInfo.productid,
          money: this.dataInfo.money
        });
        
        if (result.status == 200) {
          this.orderno = result.data.orderno;
          this.payAlipay()
        }
      } catch (err) {}
    },
    async payAlipay() {
      try {
        const result = await payAlipayRequest({
          orderno: this.orderno,
        });
        if (result.status == 200) {
          this.payUrl = result.data.payUrl;
          window.open(this.payUrl);
        }
      } catch (err) {}
    },
  },
  computed: {
    Id() {
      return this.$route.params.Id;
    },
    type() {
      return this.$route.params.type;
    },
  },
};
</script>
<style lang="less" scoped>
.el-container {
  flex-direction: column;
  height: 100%;
  .el-main {
    padding: 0;
    .contentwrap {
      width: 800px;
      margin: 0 auto 30px;
      padding: 30px 0;
    }
    /deep/.el-menu--horizontal {
      // padding: 10px 0 0 0;
      & > .el-menu-item {
        border-bottom: 2px solid #16aed0;
        padding: 4px 10px;
        height: 46px;
        line-height: 32px;
        font-size: 16px;
        color: #222;
        margin-right: 20px;
        &.is-active {
          color: #16aed0;
        }
      }
    }
  }
  .centerbox {
    margin: 40px 0 0;
    clear: both;
    overflow: hidden;
  }
  .tittleh2 {
    font-size: 18px;
    color: #000;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .titp {
    margin-bottom: 20px;
    font-size: 14px;
    color: #444;
    .red {
      color: red;
      font-size: 22px;
    }
  }
  .lineb {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dotted #e6e6e6;
  }
  .imgwrap {
    display: flex;
  }
  .imgbox {
    display: flex;
    width: 200px;
    height: 60px;
    border: 1px solid #ddd;
    margin-right: 20px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    // box-shadow: 1px 1px 0 0 #2F95E3;
    cursor: pointer;
    img {
      width: 100px;
    }
    &:hover {
      border: 1px solid #7ac5ff;
      box-shadow: 0 0 9px 0 #93cffd;
    }
    &.active {
      border: 1px solid #2f95e3;
    }
  }
  .paybtn {
    float: right;
    margin-top: 30px;
  }
}
.bannerbox img {
  width: 100%;
  height: auto;
}
</style>